<template>
  <v-btn
    v-bind="$attrs"
    class="em-secondary-button no-text-transform"
    variant="text"
    :class="{ 'px-3': display.xs && mobileIcon }"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
    <template v-if="display[mobileBreakpoint] && mobileIcon" #default>
      <EmIcon :icon="mobileIcon" />
    </template>
  </v-btn>
</template>
<script setup>
import { useDisplay } from 'vuetify'
defineProps({
  mobileIcon: { type: String, default: null },
  mobileBreakpoint: { type: String, default: 'smAndDown' }
})
const display = ref(useDisplay())
</script>
<style scoped>
.em-secondary-button.v-btn{
  background-color: rgb(var(--v-theme-bggrey));
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
